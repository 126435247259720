// @flow
import React from 'react';
import type { Node } from 'react';
import styled, { css, createGlobalStyle } from 'styled-components';
import {
  MuiThemeProvider,
  createMuiTheme,
  createGenerateClassName,
} from '@material-ui/core/styles';
import createPalette from '@material-ui/core/styles/createPalette';
import { green, purple, blue, text } from './colors';
import { create } from 'jss';
import preset from 'jss-preset-default';
import { SheetsRegistry } from 'react-jss/lib/jss';
import JssProvider from 'react-jss/lib/JssProvider';

/**
 * MUI Theme Stuff
 */

const palette = createPalette({
  type: 'light',
  primary: green, // Purple and green play nicely together.
  secondary: {
    ...purple,
  },
});

export const GlobalStyle = createGlobalStyle`
  a {
    text-shadow: none;
    text-decoration: none;
    background-image: none;
    &:hover {
      text-shadow: none;
      text-decoration: none;
      background-image: none;
    }
  }
`;

palette.text = text;
// palette.shades.light.text = text;

export const theme = createMuiTheme({
  palette,
});

const ThemeProvider = ({ children }: { children: Node }) =>
  typeof document !== 'undefined' ? (
    <MuiThemeProvider theme={theme} sheetsManager={new Map()}>
      {children}
    </MuiThemeProvider>
  ) : (
    <MuiThemeProvider theme={theme} sheetsManager={new Map()}>
      {children}
    </MuiThemeProvider>
  );

export default ThemeProvider;

/**
 * JSS Provider
 *
 */

// export const sheetsRegistry = new SheetsRegistry();
// export const generateClassName = createGenerateClassName();
// export const jss = create(preset());

// if (typeof document !== 'undefined') {
//   jss.options.insertionPoint =
//     document.getElementById('jss-insertion-point') || 'jss-insertion-point';
// }

export const query = (size: number) => (...args: any) => css`
  @media (min-width: ${size / 16}em) {
    ${css(...args)};
  }
`;

export const MAX_WIDTH = 1040;
export const MEDIUM_BREAKPOINT = 670;
export const LARGE_BREAKPOINT = 855;
export const XL_BREAKPOINT = 1000;
