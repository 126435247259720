//@flow
import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import 'typeface-roboto';
import MuiThemeProvider, { GlobalStyle } from '../style';
import type { Node } from 'react';

export default ({
  children,
  title,
  style,
}: {
  children: Node,
  style?: any,
  title?: string,
}) => (
  <Page style={style}>
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: `
    {
      "@context": "http://schema.org",
      "@type": "Organization",
      "url": "http://www.spineidaho.com",
      "logo": "http://www.spineidaho.com/logo.png",
      "contactPoint": [{
        "@type": "ContactPoint",
        "telephone": "+1-208-855-2900",
        "email": "info@spineidaho.com",
        "contactType": "customer service"
      }],
      "members": [
        {
          "name": "Samuel Jorgenson, MD",
          "url": "www.spineidaho.com/team/samuel-jorgenson,%20md"
        }
      ]
    }
  `,
      }}
    />
    {title && (
      <Helmet
        titleTemplate="Spine Idaho: %s"
        title="Spine Institute of Idaho"
        defaultTitle="Spine Institute of Idaho"
        meta={[
          {
            name: 'description',
            content:
              'The Spine Institute of Idaho is a multidisciplinary spinal medicine center. Every year, we help thousands of people get back in to life.',
          },
        ]}
      >
        <title>{title}</title>
      </Helmet>
    )}
    <MuiThemeProvider>{children}</MuiThemeProvider>
    <GlobalStyle />
  </Page>
);

const Page = styled.div`
  && {
    overflow-x: hidden;
  }
`;
