//@flow
import React from 'react';

export default ({ className, style }: { className?: string, style?: any }) => (
  <svg
    className={className}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="18"
    viewBox="0 0 28 24"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      transform="matrix(-1 0 0 1 23.9 5.1)"
      id="a"
      d="M.445 6.1c-.07.07-.133.146-.188.228-.017.026-.028.055-.044.082-.034.058-.07.116-.095.18-.015.034-.022.072-.034.107-.02.058-.042.115-.053.176-.02.098-.03.2-.03.3 0 .102.01.203.03.3.013.064.035.122.055.182.012.034.02.07.033.103.026.064.062.123.096.182.016.026.027.054.043.08.055.08.12.158.188.228l5.66 5.655c.593.594 1.555.593 2.148 0 .593-.593.593-1.555 0-2.15L5.19 8.694h13.29c.84 0 1.52-.68 1.52-1.52s-.68-1.52-1.52-1.52H5.19l3.063-3.06c.297-.296.445-.685.445-1.074 0-.39-.148-.778-.445-1.075-.592-.593-1.555-.593-2.15 0L.446 6.1z"
    />
  </svg>
);
