// @flow
import Button from '@material-ui/core/Button';
import React from 'react';
import styled from 'styled-components';
import Arrow from '../../icons/Arrow';
import { green, purple } from '../../style/colors';
import type { Node } from 'react';

export default ({
  label,
  arrow = false,
  flat = false,
  stroked = false,
  color,
  className,
  children,
  ...rest
}: {
  stroked?: boolean,
  flat?: boolean,
  label: string,
  arrow?: boolean,
  color?: 'primary' | 'secondary',
  className?: string,
  children?: Node,
  rest: any,
}) => {
  return (
    <StyledButton
      className={className}
      stroked={stroked ? 1 : 0}
      variant={!flat && !stroked ? 'contained' : 'text'}
      color={color}
      {...rest}
    >
      {label ? label : children}
      {arrow && <Arrow style={{ marginLeft: 10, width: 21 }} />}
    </StyledButton>
  );
};

/**
 * white with purple writing    default
 * purple with white writing    secondary
 * green with white writing     primary
 */

const StyledButton = styled(Button)`
  && {
    background: white;
    color: ${purple[500]};
    fill: ${purple[500]};
    ${({ color }) => {
      if (color === 'secondary')
        return `
        color: #fff;
        fill: #fff;
        background: ${purple[500]};
      `;
      if (color === 'primary')
        return `
        background: ${green[500]};
        color: #fff;
        fill: #fff;
      `;
    }};

    letter-spacing: 0.5px;
    font-weight: 400;

    ${({ variant }) =>
      variant === 'raised' &&
      `
      &:hover {
        transform: translateY(-1px);
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1),
          0 3px 6px rgba(0, 0, 0, 0.08);
      }

      &:active {
        transform: translateY(1px);
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
          0 1px 3px rgba(0, 0, 0, 0.08);
      }
    `} ${({ stroked }) =>
      stroked &&
      `
      border: 2px solid;
      background: none;

    `};
  }
`;
