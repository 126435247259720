//@flow
export const green = {
  ['50']: '#e8f9f1',
  ['100']: '#c5f1dd',
  ['200']: '#9fe7c7',
  ['300']: '#78ddb0',
  ['400']: '#5bd69f',
  ['500']: '#3ecf8e',
  ['600']: '#38ca86',
  ['700']: '#30c37b',
  ['800']: '#28bd71',
  ['900']: '#1bb25f',
  A100: '#e8fff2',
  A200: '#b5ffd4',
  A400: '#82ffb6',
  A700: '#69ffa7',
  contrastDefaultColor: 'light',
};

export const purple = {
  ['50']: '#edeefc',
  ['100']: '#d1d5f7',
  ['200']: '#b3b9f2',
  ['300']: '#959ced',
  ['400']: '#7e87e9',
  ['500']: '#6772e5',
  ['600']: '#5f6ae2',
  ['700']: '#545fde',
  ['800']: '#4a55da',
  ['900']: '#3942d3',
  A100: '#ffffff',
  A200: '#ebecff',
  A400: '#b8bcff',
  A700: '#9ea3ff',
  contrastDefaultColor: 'light',
};

export const blue = {
  ['50']: '#e9eaeb',
  ['100']: '#c7cace',
  ['200']: '#a2a7ad',
  ['300']: '#7c848c',
  ['400']: '#606973',
  ['500']: '#444f5a',
  ['600']: '#3e4852',
  ['700']: '#353f48',
  ['800']: '#2d363f',
  ['900']: '#1f262e',
  A100: '#76b5ff',
  A200: '#439aff',
  A400: '#107eff',
  A700: '#0071f6',
  contrastDefaultColor: 'light',
};

export const text = {
  primary: '#444F5A',
  secondary: '#606973',
  disabled: '#A2A7AD',
  hint: '#A2A7AD',
  icon: '#A2A7AD',
  divider: 'rgba(68, 79, 90, 0.12)',
  lightDivider: 'rgba(68, 79, 90, 0.075)',
};
